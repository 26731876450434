import { Box, Button, Link, TextField, Typography } from '@mui/material';
import {
  getServerError,
  PASSWORD_ERROR,
  SIGNIN_WITH_EMAIL_WRONG_EMAIL,
} from '../messages';
import { useEffect, useState } from 'react';
import {
  DARK_GREY,
  DEEP_PURPLE,
  DEEP_PURPLE_HOVERED,
  DEEP_PURPLE_PRESSED,
  GREEN,
  LIGHT_YELLOW,
  LIGHTER_GREY,
  PINK,
  WHITE,
} from '../styles/colors';
import { apiRequest, setSession } from '../ApiClient';
import { validateEmail, validatePassword } from '../utils';
import { Loader } from '../components/Loader';
import { ReactComponent as RedeemIcon } from '../icons/RedeemIcon.svg';
import { loadState } from '../Storage';

const styles = {
  input: {
    display: 'flex',
    '& .MuiFilledInput-root': {
      borderRadius: 0,
      color: DARK_GREY,
      background: WHITE,
      '&:hover': {
        '&::before': {
          border: 'none',
        },
        '&::after': {
          border: 'none',
        },
      },
      '&::before': {
        border: 'none',
      },
      '&::after': {
        border: 'none',
      },
    },
  },
  createAccountBtn: {
    width: '100%',
    maxWidth: '600px',
    padding: '15px 0',
    background: DEEP_PURPLE,
    borderRadius: '50px',
    boxShadow: 'none',
    fontSize: '12px',
    fontFamily: 'GeometriaExtraBold',
    fontWeight: 900,
    '&: hover': {
      background: DEEP_PURPLE_HOVERED,
    },
    '&:active': {
      background: DEEP_PURPLE_PRESSED,
    },
  },
  box1: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '300px',
    padding: '120px 20px 15px',
    bgcolor: GREEN,
    color: WHITE,
    textAlign: 'center',
  },
  box2: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minHeight: '450px',
    padding: '30px 20px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    bgcolor: LIGHTER_GREY,
    color: DARK_GREY,
    textAlign: 'center',
  },
};
export const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [discount, setDiscount] = useState('25');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { code } = loadState('redeem');
  useEffect(() => {
    setDiscount('25');
  }, []);
  const handleChangeEmail = (e) => {
    setEmailError('');
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPasswordError('');
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError(SIGNIN_WITH_EMAIL_WRONG_EMAIL);
    }
    if (!validatePassword(password)) {
      setPasswordError(PASSWORD_ERROR);
    }
    if (validatePassword(password) && validateEmail(email)) {
      setIsLoading(true);
      const resp = await apiRequest('/auth/signup', {
        email: email,
        password: password,
      });
      if (resp.ok) {
        window.fbq('track', 'CompleteRegistration');
        setSession(await resp.json());
        apiRequest('/billing/stripe/subscribe-with-promo/' + code)
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            setIsLoading(false);
            window.location.href = data.subscribe_url;
          });
      } else {
        setEmailError(await getServerError(resp));
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <>
      <Box
        sx={{ ...styles.box1, bgcolor: discount === '25' ? GREEN : PINK }}
        component="section"
      >
        <Box>
          <Typography
            sx={{ fontSize: '30px' }}
            variant="h5"
            component="h1"
            gutterBottom
          >
            You get <br />
            <span
              style={{ color: LIGHT_YELLOW }}
            >{` ${discount}% off `}</span>{' '}
            on Funexpected Plus
          </Typography>
        </Box>
        <RedeemIcon />
      </Box>
      <Box sx={styles.box2} component="section">
        <Typography
          sx={{ mb: '20px', fontWeight: 900 }}
          variant="h5"
          component="h1"
          gutterBottom
        >
          Create your account
        </Typography>
        <Typography sx={{ mb: '20px', fontSize: '14px' }} gutterBottom>
          Already have an account?{' '}
          <Link sx={{ color: DEEP_PURPLE }} href={'/redeem/signin'}>
            Sign in
          </Link>
        </Typography>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            '& .MuiTextField-root': {
              mt: 1,
              mb: 1,
              width: '100%',
              maxWidth: '600px',
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            error={!!emailError}
            sx={styles.input}
            label="Email"
            variant="filled"
            type="email"
            value={email}
            helperText={emailError && emailError}
            onChange={handleChangeEmail}
            color="secondary"
          />
          <TextField
            error={!!passwordError}
            helperText={passwordError && passwordError}
            sx={styles.input}
            label="Password (6+ characters)"
            variant="filled"
            type="password"
            value={password}
            onChange={handleChangePassword}
            onKeyDown={handleKeyDown}
            color="secondary"
          />
          <Box
            sx={{
              width: '100%',
              maxWidth: '600px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& button': { m: 1 },
            }}
          >
            <Button
              sx={styles.createAccountBtn}
              onClick={handleSubmit}
              variant="contained"
            >
              Create Account
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{ opacity: 0.5, fontSize: '14px', textAlign: 'left' }}
          >
            By registering, you agree to our{' '}
            <Link
              color="inherit"
              href="https://funexpectedapps.com/terms"
              target="_blank"
            >
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link
              color="inherit"
              href="https://funexpectedapps.com/privacy"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      </Box>
      {isLoading && <Loader />}
    </>
  );
};
