import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  getServerError,
  PASSWORD_ERROR,
  SIGNIN_WITH_EMAIL_WRONG_EMAIL,
} from '../messages';
import { useState } from 'react';
import {
  DARK_GREY,
  DEEP_PURPLE,
  DEEP_PURPLE_HOVERED,
  DEEP_PURPLE_PRESSED,
  LIGHTER_GREY,
  WHITE,
} from '../styles/colors';
import { apiRequest, setSession } from '../ApiClient';
import { validateEmail, validatePassword } from '../utils';
import { Loader } from '../components/Loader';
import { loadState } from '../Storage';

const styles = {
  input: {
    display: 'flex',
    '& .MuiFilledInput-root': {
      borderRadius: 0,
      color: DARK_GREY,
      background: WHITE,
      '&:hover': {
        '&::before': {
          border: 'none',
        },
        '&::after': {
          border: 'none',
        },
      },
      '&::before': {
        border: 'none',
      },
      '&::after': {
        border: 'none',
      },
    },
  },
  createAccountBtn: {
    width: '100%',
    maxWidth: '600px',
    padding: '15px 0',
    background: DEEP_PURPLE,
    borderRadius: '50px',
    boxShadow: 'none',
    fontSize: '12px',
    fontFamily: 'GeometriaExtraBold',
    fontWeight: 900,
    '&: hover': {
      background: DEEP_PURPLE_HOVERED,
    },
    '&:active': {
      background: DEEP_PURPLE_PRESSED,
    },
  },
  box2: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '60px 20px 35px',
    bgcolor: LIGHTER_GREY,
    color: DARK_GREY,
    textAlign: 'center',
  },
};
export const SignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { code } = loadState('redeem', '');
  const { code: queryCode } = useParams();
  const redeemCode = code || queryCode;

  const handleChangeEmail = (e) => {
    setEmailError('');
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPasswordError('');
    setPassword(e.target.value);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError(SIGNIN_WITH_EMAIL_WRONG_EMAIL);
    }
    if (!validatePassword(password)) {
      setPasswordError(PASSWORD_ERROR);
    }
    if (validatePassword(password) && validateEmail(email)) {
      setIsLoading(true);
      const resp = await apiRequest('/auth/signin', {
        email: email,
        password: password,
      });
      if (resp.ok) {
        setSession(await resp.json());
        apiRequest('/billing/stripe/subscribe-with-promo/' + redeemCode)
          .then((resp) => {
            return resp.json();
          })
          .then((data) => {
            setIsLoading(false);
            window.location.href = data.subscribe_url;
          });
      } else {
        setEmailError(await getServerError(resp));
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  return (
    <>
      <Box sx={styles.box2} component="section">
        <Typography
          sx={{ mb: '20px', fontWeight: 900 }}
          variant="h5"
          component="h1"
          gutterBottom
        >
          Sign In
        </Typography>
        <Typography sx={{ mb: '20px', fontSize: '14px' }} gutterBottom>
          Forget your password?{' '}
          <Link
            sx={{ color: DEEP_PURPLE, textDecoration: 'none' }}
            href={'/redeem/restore'}
          >
            Restore
          </Link>
        </Typography>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            '& .MuiTextField-root': {
              mt: 1,
              mb: 1,
              width: '100%',
              maxWidth: '600px',
            },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            error={!!emailError}
            sx={styles.input}
            label="Email"
            variant="filled"
            type="email"
            value={email}
            helperText={emailError && emailError}
            onChange={handleChangeEmail}
            color="secondary"
          />
          <TextField
            error={!!passwordError}
            helperText={passwordError && passwordError}
            sx={styles.input}
            label="Password"
            variant="filled"
            type="password"
            value={password}
            onChange={handleChangePassword}
            onKeyDown={handleKeyDown}
            color="secondary"
          />
          <Box
            sx={{
              width: '100%',
              maxWidth: '600px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& button': { m: 1 },
            }}
          >
            <Button
              sx={styles.createAccountBtn}
              onClick={handleSubmit}
              variant="contained"
            >
              Sign in
            </Button>
          </Box>
        </Box>
      </Box>
      {isLoading && <Loader />}
    </>
  );
};
