export const DEEP_PURPLE = '#6A56CC';
export const DEEP_PURPLE_HOVERED = '#5848aa';
export const DEEP_PURPLE_PRESSED = '#5948ab';
export const WHITE = '#FFFFFF';
export const WHITE_HOVERED = '#ebebeb';
export const WHITE_PRESSED = '#d6d6d6';
export const DARK_GREY = '#4D4D4D';
export const GREY = '#AAAAAA';
export const LIGHT_GREY = '#D4D4D4';
export const LIGHTER_GREY = '#F1F1F1';
export const YELLOW = '#F9B067';
export const YELLOW_HOVERED = '#e5a25f';
export const YELLOW_PRESSED = '#d19456';
export const LIGHT_YELLOW = '#F9CDAF';
export const GREEN = '#34B778';
export const RED = '#F02D2D';
export const PINK = '#EF81AC';
export const APPLE_BLACK = '#212121';