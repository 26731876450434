import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import {
  DEEP_PURPLE,
  DEEP_PURPLE_HOVERED,
  DEEP_PURPLE_PRESSED,
  GREEN,
  LIGHT_YELLOW,
  LIGHTER_GREY,
  WHITE,
  WHITE_HOVERED,
  WHITE_PRESSED,
} from '../styles/colors';
import { ReactComponent as GreenHi } from '../icons/GreenHi.svg';
import { ReactComponent as Ducks } from '../icons/Ducks.svg';
import { ReactComponent as Check } from '../icons/Check.svg';
import { ReactComponent as Three } from '../icons/3-4.svg';
import { ReactComponent as Five } from '../icons/5-6.svg';
import { ReactComponent as Seven } from '../icons/7+.svg';
import { SliderItem } from './SliderItem';

const styles = {
  box1: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '120px 20px 35px',
    bgcolor: DEEP_PURPLE,
    color: WHITE,
    textAlign: 'center',
  },
  box2: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '450px',
    padding: '30px 20px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    bgcolor: WHITE,
    color: GREEN,
    textAlign: 'center',
  },
  box3: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '500px',
    padding: '40px 20px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    bgcolor: GREEN,
    color: WHITE,
    textAlign: 'center',
  },
  container: {
    padding: 0,
  },
};

const Slider = () => (
  <Box sx={{ mb: '25px', maxWidth: '100%', overflowX: 'scroll' }}>
    <Box sx={{ display: 'flex', flexWrap: 'no-wrap', gap: '20px' }}>
      <SliderItem
        image={<Three />}
        header="For 3-4 years"
        rowsArr={['Recognize numbers', 'Compare quantities', 'Learn shapes']}
      />
      <SliderItem
        image={<Five />}
        header="For 5-6 years"
        rowsArr={[
          'Understand place value',
          'Learn 2D and 3D shapes',
          'Recognize visual patterns',
        ]}
      />
      <SliderItem
        image={<Seven />}
        header="For 7+ years"
        rowsArr={[
          'Learn addition strategies',
          'Split and combine shapes',
          'Reason by analogy',
        ]}
      />
    </Box>
  </Box>
);

const ClaimButton = ({ onClick, color = 'primary' }) => (
  <Button
    onClick={onClick}
    sx={{
      width: '100%',
      padding: '15px 0',
      border: 'none',
      borderRadius: '50px',
      cursor: 'pointer',
      background: color === 'primary' ? WHITE : DEEP_PURPLE,
      '&:hover': {
        background: color === 'primary' ? WHITE_HOVERED : DEEP_PURPLE_HOVERED,
      },
      '&:active': {
        background: color === 'primary' ? WHITE_PRESSED : DEEP_PURPLE_PRESSED,
      },
    }}
  >
    <Typography
      sx={{
        color: color === 'primary' ? DEEP_PURPLE : WHITE,
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: 900,
        fontFamily: 'GeometriaExtraBold',
      }}
    >
      Claim my friend pass
    </Typography>
  </Button>
);

ClaimButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['primary', 'secondary']),
};

export const Claim = ({ handleChangeStep, trialDays }) => {
  const [isIntersecting, setIsIntersecting] = useState(true);
  const ref = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);
  return (
    <>
      <Box sx={styles.box1} component="section">
        <Box sx={{ maxWidth: '640px', width: '100%', padding: '0 20px' }}>
          <Typography
            sx={{ fontSize: '30px' }}
            variant="h5"
            component="h1"
            gutterBottom
          >
            Claim your <span style={{ color: LIGHT_YELLOW }}>{`${trialDays}-Day`} </span>
            Friend Pass
          </Typography>
          <Typography sx={{ mb: '20px' }} variant="subtitle1">
            <span style={{ color: LIGHT_YELLOW }}>Your friend</span> shared
            <div>Funexpected Math with you</div>
          </Typography>
          <Box
            ref={ref}
            sx={{
              mb: '20px',
              ml: 'auto',
              mr: 'auto',
              width: '100%',
              maxWidth: '600px',
            }}
          >
            <ClaimButton onClick={handleChangeStep} />
          </Box>
          <Box>
            <Typography sx={{ opacity: 0.5, fontSize: '14px' }}>
              Exclusive offer for new members only
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.box2} component="section">
        <GreenHi />
        <Typography
          sx={{ mb: '60px', fontSize: '30px' }}
          variant="h5"
          component="h2"
          gutterBottom
        >
          10 minutes of daily math with a personal tutor
        </Typography>
        <Slider />
      </Box>
      <Box sx={styles.box3} component="section">
        <Typography variant="h5" component="h2" gutterBottom>
          Boost your kid’s math thinking
        </Typography>
        <List>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: '40px' }}>
              <Check style={{ color: WHITE }} />
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: '16px' }}
              primary="Access 1000+ tasks to boost Number Sense, Logic, Spatial Skills and more"
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: '40px' }}>
              <Check style={{ color: WHITE }} />
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: '16px' }}
              primary="Master all math concepts crucial for school"
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: '40px' }}>
              <Check style={{ color: WHITE }} />
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: '16px' }}
              primary="Get detailed progress reports for your child"
            />
          </ListItem>
        </List>
        <Ducks />
      </Box>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          zIndex: 1,
          transform: isIntersecting ? 'translateY(100%)' : 'translateY(0)',
          transition: 'transform .5s',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          padding: '20px',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          background: LIGHTER_GREY,
        }}
      >
        <ClaimButton onClick={handleChangeStep} color="secondary" />
      </div>
    </>
  );
};

Claim.propTypes = {
  handleChangeStep: PropTypes.func,
  trialDays: PropTypes.number,
};
