import { Box, Button, Link, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { convertErrorMessage } from '../messages';
import {
  DARK_GREY,
  DEEP_PURPLE,
  WHITE,
  YELLOW,
  YELLOW_HOVERED,
  YELLOW_PRESSED,
} from '../styles/colors';
import { apiRequest} from '../ApiClient';
import { router } from '../router/router';
import { saveState } from '../Storage';
import { Loader } from '../components/Loader';

const styles = {
  input: {
    display: 'flex',
    '& .MuiFilledInput-root': {
      height: '60px',
      borderRadius: 0,
      border: 'none',
      color: DARK_GREY,
      background: WHITE,
      '&:hover': {
        '&::before': {
          border: 'none',
        },
        '&::after': {
          border: 'none',
        },
      },
      '&::before': {
        border: 'none',
      },
      '&::after': {
        border: 'none',
      },
    },
    '& .MuiFilledInput-input': {
      background: WHITE,
    },
  },
  box1: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    padding: '60px 20px 35px',
    bgcolor: DEEP_PURPLE,
    color: WHITE,
    textAlign: 'center',
  },
  container: {
    padding: 0,
  },
};

const CheckCodeButton = ({ onClick, disabled }) => (
  <Button
    onClick={onClick}
    disabled={disabled}
    sx={{
      width: '100%',
      padding: '15px 0',
      border: 'none',
      borderRadius: '50px',
      cursor: 'pointer',
      background: YELLOW,
      '&:hover': {
        background: YELLOW_HOVERED,
      },
      '&:active': {
        background: YELLOW_PRESSED,
      },
    }}
  >
    <Typography
      sx={{
        color: WHITE,
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: 900,
        fontFamily: 'GeometriaExtraBold',
      }}
    >
      Check my code
    </Typography>
  </Button>
);

CheckCodeButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export const RedeemCode = () => {
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const [isLoading, setLoading] = useState(false);
  const handleChangeCode = (e) => {
    setCodeError('');
    setCode(e.target.value);
  };

  const handleCheckCode = () => {
    const formattedCode = code.trim();
    if (formattedCode === '') {
      setCodeError('Please enter your code');
      return;
    }
    if (isLoading) {
      return;
    }
    console.log('requesting');
    setLoading(true);
    apiRequest('/billing/stripe/check_code', { code: formattedCode })
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        if (data.err) {
          setCodeError(convertErrorMessage(data.err));
          setLoading(false);
        } else {
          saveState('redeem', { code: data.code });
          router.navigate(`/redeem/signup`);
        }
      });
    console.log('checked');
  };
  return (
    <>
      <Box sx={styles.box1} component="section">
        <Box sx={{ maxWidth: '640px', width: '100%', padding: '0' }}>
          <Typography
            sx={{ fontSize: '30px', mb: '40px' }}
            variant="h5"
            component="h1"
          >
            Redeem my code
          </Typography>
          <Box
            // component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& .MuiTextField-root': {
                mt: 1,
                mb: 1,
                width: '100%',
                maxWidth: '600px',
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              error={!!codeError}
              sx={styles.input}
              label="Enter your code here"
              variant="filled"
              type="text"
              value={code}
              helperText={codeError && codeError}
              onChange={handleChangeCode}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleCheckCode();
                }
              }}
              color="secondary"
            />
            <Box
              sx={{
                width: '100%',
                maxWidth: '600px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& button': { m: 1 },
              }}
            >
              <CheckCodeButton onClick={handleCheckCode} disabled={isLoading} />
            </Box>
          </Box>
          <Box
            sx={{
              mb: '20px',
              ml: 'auto',
              mr: 'auto',
              width: '100%',
              maxWidth: '600px',
            }}
          ></Box>
          <Box>
            <Typography sx={{ fontSize: '14px', color: WHITE, opacity: 0.5 }}>
              Need Help? Contact Us
            </Typography>
            <Link
              sx={{
                color: WHITE,
                fontSize: '14px',
                opacity: 0.5,
                borderBottom: `1px solid ${WHITE}`,
              }}
              underline="none"
              href="mailto: support@funexpected.org"
            >
              support@funexpected.org
            </Link>
          </Box>
        </Box>
      </Box>
      {isLoading && <Loader />}
    </>
  );
};

RedeemCode.propTypes = {
  handleChangeStep: PropTypes.func,
};
