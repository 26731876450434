import {
  Box,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { setSession } from '../ApiClient';
import { Layout } from '../components/Layout';
import {
  APPLE_BLACK,
  DARK_GREY,
  DEEP_PURPLE,
  GREY,
  LIGHTER_GREY,
  WHITE,
} from '../styles/colors';
import { ReactComponent as GetStartedIcon } from '../icons/GetStarted.svg';
import { ReactComponent as GreenCheck } from '../icons/GreenCheck.svg';
import { ReactComponent as SecondStep } from '../icons/2.svg';
import { ReactComponent as ThirdStep } from '../icons/3.svg';
import { ReactComponent as Google } from '../icons/Download_with_Google.svg';
import { ReactComponent as Apple } from '../icons/Download_with_Apple.svg';
import { loadState, saveState } from '../Storage';
import { useParams } from 'react-router-dom';

const styles = {
  box1: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '300px',
    padding: '120px 20px 40px',
    bgcolor: DEEP_PURPLE,
    color: WHITE,
    textAlign: 'center',
  },
  box2: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-40px',
    minHeight: '440px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px 20px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    bgcolor: LIGHTER_GREY,
    color: DARK_GREY,
    textAlign: 'center',
  },
};

const DownloadLink = ({ children, href }) => (
  <a
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '130px',
      height: '35px',
      borderRadius: '50px',
      background: APPLE_BLACK,
    }}
    href={href}
  >
    {children}
  </a>
);
DownloadLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

const Footer = () => (
  <Box sx={{ display: 'flex', gap: '10px' }}>
    <DownloadLink href="https://play.google.com/store/apps/details?id=ltd.funexpected.math&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <Google />
    </DownloadLink>
    <DownloadLink href="https://apps.apple.com/us/app/funexpected-math-for-kids/id1473965253">
      <Apple />
    </DownloadLink>
  </Box>
);

export const GetStarted = () => {
  const { period } = useParams();
  const predicted_ltv = 
    period === 'monthly' ? 18 :
    period === 'three-month' ? 23 :
    period === 'year' ? 30 : 30;
  // called once
  useEffect(() => {
    window.fbq('track', 'StartTrial', { predicted_ltv });
  }, []);
  setSession(null);
  const data = loadState('redeem');
  console.log(data)
  useEffect(() => {
    return saveState('redeem', null);
  }, []);
  return (
    <Layout color="secondary" footerActions={<Footer />}>
      <Box sx={styles.box1} component="section">
        <Box>
          <Typography
            sx={{ fontSize: '30px' }}
            variant="h5"
            component="h1"
            gutterBottom
          >
            You’re all set! Let’s get started
          </Typography>
        </Box>
        <GetStartedIcon />
      </Box>
      <Box sx={styles.box2} component="section">
        <Typography variant="h5" component="h2" gutterBottom>
          Your next steps
        </Typography>
        <List sx={{ mb: '40px' }}>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: '50px' }}>
              <GreenCheck />
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: '16px', textDecoration: 'line-through' }}
              primary="Sign up for your Funexpected Math offer"
              // In future we should differentiate Friend Pass and other offers
              // but for now easy hotfix is to just show the same text for all
              // primary={
              //   data && data.code
              //     ? 'Sign up for your Funexpected Math offer'
              //     : 'Sign up for your Funexpected Friend pass'
              // }
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: '50px' }}>
              <SecondStep />
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: '16px' }}
              primary="Download the Funexpected app to your mobile device"
            />
          </ListItem>
          <ListItem disablePadding alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: '50px' }}>
              <ThirdStep />
            </ListItemIcon>
            <ListItemText
              sx={{ fontSize: '16px' }}
              primary="Log in to the account you just created"
            />
          </ListItem>
        </List>
        <Box>
          <Typography sx={{ fontSize: '14px', color: GREY }}>
            Need Help? Contact Us
          </Typography>
          <Link
            sx={{ color: DEEP_PURPLE, fontSize: '14px' }}
            underline="none"
            href="mailto: support@funexpected.org"
          >
            support@funexpected.org
          </Link>
        </Box>
      </Box>
    </Layout>
  );
};
