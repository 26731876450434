import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, CssBaseline, createTheme } from '@mui/material';
import { router } from './router/router';
import Geometria from './fonts/Geometria-Regular.woff';
import GeometriaExtraBold from './fonts/Geometria-ExtraBold.woff2';
import { DEEP_PURPLE } from './styles/colors';

const theme = createTheme({
  typography: {
    fontFamily: 'Geometria',
    h5: {
      fontFamily: 'GeometriaExtraBold',
    },
    h6: {
      fontFamily: 'GeometriaExtraBold',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Geometria';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Geometria'), local('Geometria-Regular'), url(${Geometria}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        },
        @font-face {
          font-family: 'GeometriaExtraBold';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: local('GeometriaExtraBold'), local('Geometria-ExtraBold'), url(${GeometriaExtraBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label.Mui-focused': {
            color: DEEP_PURPLE,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '&:before, &:after': {
            borderBottom: `2px solid ${DEEP_PURPLE}`,
          },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: `2px solid ${DEEP_PURPLE}`,
          },
          '&.Mui-focused:after': {
            borderBottom: `2px solid ${DEEP_PURPLE}`,
          },
        },
      },
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
