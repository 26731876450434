import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { DEEP_PURPLE, WHITE } from '../styles/colors';

export const Loader = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
      background: WHITE,
      opacity: 0.5,
      color: DEEP_PURPLE,
    }}
  >
    <CircularProgress sx={{ mt: '-60px' }} color={'inherit'} />
  </Box>
);
