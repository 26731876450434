import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as HeaderIcon } from '../icons/Funexpected.svg';
import { DEEP_PURPLE, WHITE } from '../styles/colors';

const colors = {
  bgColor: {
    primary: DEEP_PURPLE,
    secondary: WHITE,
  },
  iconColor: {
    primary: WHITE,
    secondary: DEEP_PURPLE,
  },
};

export const Header = ({ color = 'primary' }) => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '60px',
      background: colors.bgColor[color]
    }}
  >
    <Link href="https://funexpectedapps.com/">
      <HeaderIcon style={{ color: colors.iconColor[color] }} />
    </Link>
  </div>
);

Header.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
};
