import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as Check } from '../icons/Check.svg';
import { DEEP_PURPLE, GREY } from '../styles/colors';

const Step = ({ stepText, color, isNotFirstStep }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        flexGrow: isNotFirstStep ? 1 : 0,
      }}
    >
      {isNotFirstStep && (
        <div
          style={{
            width: 'auto',
            minWidth: '20px',
            height: '2px',
            background: color,
            flexGrow: 1,
          }}
        ></div>
      )}
      <Check style={{ color, width: '12px' }} />
      <Typography
        sx={{
          color,
          fontSize: '12px',
          fontWeight: 900,
          fontFamily: 'GeometriaExtraBold',
        }}
      >
        {stepText}
      </Typography>
    </Box>
  );
};
Step.propTypes = {
  stepText: PropTypes.string,
  color: PropTypes.string,
  isNotFirstStep: PropTypes.bool,
};
export const Stepper = ({ currentStepIndex, steps }) => {
  return (
    <Box
      sx={{
        mt: '25px',
        mb: '80px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '600px',
        width: '100%',
        gap: '4px',
      }}
    >
      {steps.map((step, index) => (
        <Step
          stepText={step}
          key={index}
          color={currentStepIndex >= index ? DEEP_PURPLE : GREY}
          isNotFirstStep={index !== 0}
        />
      ))}
    </Box>
  );
};

Stepper.propTypes = {
  currentStepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string),
};
