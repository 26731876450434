export const SIGNIN_WITH_EMAIL_WRONG_EMAIL = 'Valid email required';
export const PASSWORD_ERROR = 'Password: min. 6 characters';

export const convertErrorMessage = (err) => {
  switch (err) {
    case 'INVALID_CODE':
      return 'The promo code is not valid';
    default:
    return 'Somethings wrong. Already working on it';

  }
};

export const getServerError = async (resp) => {
  const data = await resp.json();
  switch (data.message) {
    case 'INVALID_PASSWORD':
      return 'The password is invalid. Restore the password.';
    case 'USER_EXISTS':
      return 'Account already exists. Sign in or create new.';
    case 'ALREADY_EXISTS':
      return 'Account already exists. Sign in or create new.';
    case 'EMAIL_NOT_FOUND':
      return 'No account using this email. Please sign up.';
    case 'WEAK_PASSWORD':
      return 'Password: min. 6 characters.';
    default:
      return 'Somethings wrong. Already working on it';
  }
};
