import { Outlet } from 'react-router-dom';
import { Layout } from '../components/Layout';

export const RedeemRoot = () => {
  return (
    <Layout color="secondary">
      <Outlet />
    </Layout>
  );
};
