import { Container } from '@mui/material';
import PropTypes from 'prop-types';
import { LIGHTER_GREY } from '../styles/colors';
import { Footer } from './Footer';
import { Header } from './Header';

export const Layout = ({ children, footerActions, color }) => {
  return (
    <Container
      sx={{
        minHeight: '100vh',
        background: LIGHTER_GREY,
      }}
      maxWidth="false"
      disableGutters
    >
      <Header color={color} />
      {children}
      {footerActions && <Footer color={color}>{footerActions}</Footer>}
    </Container>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  footerActions: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary']),
};
