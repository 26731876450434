import { Box, Button, TextField, Typography } from '@mui/material';
import { getServerError, SIGNIN_WITH_EMAIL_WRONG_EMAIL } from '../messages';
import { useState } from 'react';
import {
  DARK_GREY,
  DEEP_PURPLE,
  DEEP_PURPLE_HOVERED,
  DEEP_PURPLE_PRESSED,
  LIGHTER_GREY,
  WHITE,
} from '../styles/colors';
import { apiRequest } from '../ApiClient';
import { validateEmail } from '../utils';
import { Loader } from '../components/Loader';
import { buildAuthState } from '../Auth';
import { loadState } from '../Storage';

const styles = {
  input: {
    display: 'flex',
    '& .MuiFilledInput-root': {
      borderRadius: 0,
      color: DARK_GREY,
      background: WHITE,
      '&:hover': {
        '&::before': {
          border: 'none',
        },
        '&::after': {
          border: 'none',
        },
      },
      '&::before': {
        border: 'none',
      },
      '&::after': {
        border: 'none',
      },
    },
  },
  createAccountBtn: {
    width: '100%',
    maxWidth: '600px',
    padding: '15px 0',
    background: DEEP_PURPLE,
    borderRadius: '50px',
    boxShadow: 'none',
    fontSize: '12px',
    fontFamily: 'GeometriaExtraBold',
    fontWeight: 900,
    '&: hover': {
      background: DEEP_PURPLE_HOVERED,
    },
    '&:active': {
      background: DEEP_PURPLE_PRESSED,
    },
  },
  box2: {
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: '60px 20px 35px',
    bgcolor: LIGHTER_GREY,
    color: DARK_GREY,
    textAlign: 'center',
  },
};
export const Restore = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [email, setEmail] = useState('');
  const { code } = loadState("redeem", {code: "SCHOOL25"});

  const handleChangeEmail = (e) => {
    setEmailError('');
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError(SIGNIN_WITH_EMAIL_WRONG_EMAIL);
    }
    if (validateEmail(email)) {
      setIsLoading(true);
      let callback = buildAuthState(`/redeem/signin/${code}`)
      const resp = await apiRequest('/auth/request_reset/', {
        email: email,
        cb: callback
      });
      if (resp.ok) {
        setResetRequested(true);
      } else {
        setEmailError(await getServerError(resp));
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };
  if (resetRequested) {
    return (
      <>
      <Box sx={styles.box2} component="section">
        <Typography
          sx={{ mb: '20px', fontWeight: 900 }}
          variant="h5"
          component="h1"
          gutterBottom
        >
          Check your email!
        </Typography>
        <Typography sx={{ mb: '20px' }} variant="subtitle1">
          <div>
            We just sent a temporary password link to
            <b> {email}. </b>
            Please, check your inbox.
          </div>
        </Typography>
      </Box>
      </>
    )
  } else {
    return (
      <>
        <Box sx={styles.box2} component="section">
          <Typography
            sx={{ mb: '20px', fontWeight: 900 }}
            variant="h5"
            component="h1"
            gutterBottom
          >
            Enter your current email
          </Typography>
          <Typography sx={{ mb: '20px' }} variant="subtitle1">
            <div>We will send you email with link to the password restoration page</div>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              '& .MuiTextField-root': {
                mt: 1,
                mb: 1,
                width: '100%',
                maxWidth: '600px',
              },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              error={!!emailError}
              sx={styles.input}
              label="Email"
              variant="filled"
              type="email"
              value={email}
              helperText={emailError && emailError}
              onChange={handleChangeEmail}
              onKeyDown={handleKeyDown}
              color="secondary"
            />
            <Box
              sx={{
                width: '100%',
                maxWidth: '600px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& button': { m: 1 },
              }}
            >
              <Button
                sx={styles.createAccountBtn}
                onClick={handleSubmit}
                variant="contained"
              >
                Send Email
              </Button>
            </Box>
          </Box>
        </Box>
        {isLoading && <Loader />}
      </>
    );
  }
};
