import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { DARK_GREY } from '../styles/colors';
export const SliderItem = ({ image, header, rowsArr }) => {
  return (
    <Box
      sx={{
        mb: '20px',
        minWidth: '180px',
        color: DARK_GREY,
        textAlign: 'left',
      }}
    >
      <Box sx={{ mb: '20px' }}>{image}</Box>
      <Typography
        sx={{ mb: '10px', fontSize: '16px', fontWeight: 900 }}
        variant="h6"
      >
        {header}
      </Typography>
      {rowsArr.map((row, index) => (
        <Typography sx={{ fontSize: '16px' }} key={index}>
          {row}
        </Typography>
      ))}
    </Box>
  );
};

SliderItem.propTypes = {
  image: PropTypes.node,
  header: PropTypes.string,
  rowsArr: PropTypes.arrayOf(PropTypes.string).isRequired,
};
