import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { Claim } from '../components/Claim';
import { FreeTrial } from '../components/FreeTrial';
import { Layout } from '../components/Layout';
import { SignUp } from '../components/SignUp';
import { Stepper } from '../components/Stepper';
import { loadState } from '../Storage';
import { DARK_GREY, DEEP_PURPLE, LIGHTER_GREY, WHITE } from '../styles/colors';

const steps = ['introduction', 'signup', 'subscription'];
const stepsDictionary = { signup: 'Sign up', subscription: 'Free trial' };

const stepperSteps = ['Sign up', 'Free trial', 'Get started'];

const ClaimButton = ({ onClick }) => (
  <button
    onClick={onClick}
    style={{
      width: '100%',
      maxWidth: '280px',
      padding: '15px 0',
      border: 'none',
      borderRadius: '50px',
      background: DEEP_PURPLE,
    }}
  >
    <Typography
      variant="h6"
      sx={{
        color: WHITE,
        textTransform: 'uppercase',
        fontSize: '10px',
        fontWeight: 900,
      }}
    >
      Claim my friend pass
    </Typography>
  </button>
);

ClaimButton.propTypes = {
  onClick: PropTypes.func,
};

export const Root = () => {
  const params = useParams();
  const trialId = params.trialId || "bb2e3ad3-b2c7-456b-8712-6b0f23804c8e";
  const trialDays = params.trialDays || 30;
  const session = loadState('session', null);
  const prevStepRef = useRef('introduction');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [step, setStep] = useState('introduction');
  const color = step === 'introduction' ? 'primary' : 'secondary';
  const changeStep = (stepName) => (e) => {
    e.preventDefault();
    setSearchParams(`step=${stepName}`);
  };
  useEffect(() => {
    if (searchParams.get('step') === null && step !== 'introduction') {
      setStep('introduction');
    }
    if (
      searchParams.get('step') !== null &&
      !steps.includes(searchParams.get('step'))
    ) {
      setSearchParams('step=introduction');
    }
  }, []);
  useEffect(() => {
    if (searchParams.get('step') === null) {
      setStep('introduction');
    } else if (searchParams.get('step') !== null && !session) {
      setStep(searchParams.get('step'));
    } else if (
      session &&
      searchParams.get('step') === 'signup' &&
      prevStepRef.current === 'subscription'
    ) {
      navigate(-1)
      setStep('introduction');
    } else if (
      session &&
      searchParams.get('step') === 'signup' &&
      prevStepRef.current === 'introduction'
    ) {
      navigate(1)
      setStep('subscription');
    } else if (session && searchParams.get('step') === 'signup') {
      setSearchParams(null);
      setStep('introduction');
    } else {
      setStep(searchParams.get('step'));
    }
  }, [searchParams.get('step')]);
  useEffect(() => {
    prevStepRef.current = step;
  }, [step]);
  return (
    <Layout color={color}>
      {step === 'introduction' && (
        <Claim
          trialDays={trialDays} handleChangeStep={changeStep(
            session === null ? 'signup' : 'subscription'
          )}
        />
      )}
      {step !== 'introduction' && (
        <Box
          sx={{
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            minHeight: '100vh',
            padding: '60px 20px',
            bgcolor: LIGHTER_GREY,
            color: DARK_GREY,
            textAlign: 'center',
          }}
          component="section"
        >
          <Stepper
            steps={stepperSteps}
            currentStepIndex={stepperSteps.indexOf(stepsDictionary[step])}
          />
          {step === 'signup' && (
            <SignUp changeStep={changeStep('subscription')} />
          )}
          {step === 'subscription' && <FreeTrial trialId={trialId} />}
        </Box>
      )}
    </Layout>
  );
};
