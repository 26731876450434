import PropTypes from 'prop-types';
import { LIGHTER_GREY, WHITE } from '../styles/colors';

const colors = {
  bgColor: {
    primary: LIGHTER_GREY,
    secondary: WHITE,
  },
};
export const Footer = ({ children, color = 'primary' }) => (
  <div
    style={{
      position: 'fixed',
      bottom: 0,
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '20px',
      borderTopLeftRadius: '20px',
      borderTopRightRadius: '20px',
      background: colors.bgColor[color],
    }}
  >
    {children}
  </div>
);

Footer.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['primary', 'secondary']),
};
