import { useParams, Navigate } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { setSession } from './ApiClient';
import AppleLogin from 'react-apple-login'
import PropTypes from 'prop-types'

export const Auth = () => {
    const { payload, next } = useParams();
    const session = JSON.parse(Base64.decode(payload));
    const nextPath = Base64.decode(next);
    console.log("authficating", nextPath, session);
    setSession(session);
    return (
        <Navigate to={nextPath || "/"}/>
    )
}

export const buildAuthReturnUrl = (nextPath) => {
    const next = Base64.encode(nextPath);
    return `${window.location.protocol}//${window.location.host}/auth/:payload/${next}`;
}

export const buildAuthState = (nextPath) => {
    return Base64.encode(buildAuthReturnUrl(nextPath))
}

export const SignUpWithApple = ({ nextPath, render }) => {
    return (
        <AppleLogin
            clientId="ltd.funexpected.teachers"
            redirectURI="https://api.funexpected.cloud/auth/signup_with_apple"
            usePopup={false}
            state={buildAuthState(nextPath)}
            scope="email name"
            render={render}
        />
    )
}

SignUpWithApple.propTypes = {
    nextPath: PropTypes.string,
    render: PropTypes.func
}