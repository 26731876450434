import { Box, Button, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  DARK_GREY,
  DEEP_PURPLE,
  DEEP_PURPLE_HOVERED,
  DEEP_PURPLE_PRESSED,
  GREY,
  RED,
  WHITE,
  WHITE_HOVERED,
  WHITE_PRESSED,
  YELLOW,
  YELLOW_HOVERED,
  YELLOW_PRESSED,
} from '../styles/colors';
import { apiRequest } from '../ApiClient';
import { Loader } from './Loader';

const PlanButton = ({ product, onClick, selected }) => (
  <Button
    onClick={onClick}
    sx={{
      marginBottom: '15px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      maxWidth: '600px',
      padding: '17px 20px',
      border: 'none',
      borderRadius: 0,
      background: selected ? YELLOW : WHITE,
      color: selected ? WHITE : DARK_GREY,
      cursor: 'pointer',
      '&: hover': {
        background: selected ? YELLOW_HOVERED : WHITE_HOVERED,
      },
      '&:active': {
        background: selected ? YELLOW_PRESSED : WHITE_PRESSED,
      },
    }}
  >
    <Box sx={{ textAlign: 'left' }}>
      <Typography
        sx={{
          fontSize: '14px',
          color: 'inherit',
          fontWeight: 900,
          fontFamily: 'GeometriaExtraBold',
        }}
      >
        {product.title}
        {product.off > 0.0 && (
          <s
            style={{
              color: selected ? WHITE : GREY,
              marginLeft: '10px',
              fontWeight: 400,
              fontFamily: 'Geometria',
            }}
          >
            ${(product.price / (1.0 - product.off)).toFixed(2)}
          </s>
        )}
      </Typography>
      {product.interval === 'year' ? (
        <Typography
          sx={{
            fontSize: '14px',
            color: 'inherit',
            fontWeight: 900,
            fontFamily: 'GeometriaExtraBold',
          }}
        >
          ${product.price.toFixed(2)}/{product.interval}
        </Typography>
      ) : (
        <span></span>
      )}
    </Box>
    {product.interval === 'year' ? (
      <Box>
        <Typography
          sx={{
            fontSize: '14px',
            color: 'inherit',
            fontWeight: 900,
            fontFamily: 'GeometriaExtraBold',
          }}
        >
          ${(product.price / 12.0).toFixed(2) - 0.01}/month
        </Typography>
      </Box>
    ) : (
      <Box>
        <Typography
          sx={{
            fontSize: '14px',
            color: 'inherit',
            fontWeight: 900,
            fontFamily: 'GeometriaExtraBold',
          }}
        >
          ${product.price.toFixed(2)}/{product.interval}
        </Typography>
      </Box>
    )}
    {product.off > 0.0 && (
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          width: '80px',
          textAlign: 'center',
          textTransform: 'uppercase',
          background: RED,
        }}
      >
        <Typography
          sx={{
            color: WHITE,
            fontSize: '10px',
            fontWeight: 900,
            fontFamily: 'GeometriaExtraBold',
          }}
        >
          {product.off * 100}% off
        </Typography>
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            borderTop: '6px solid transparent',
            borderBottom: '6px solid transparent',
            borderLeft: `6px solid ${selected ? YELLOW : WHITE}`,
          }}
        ></div>
      </div>
    )}
  </Button>
);

PlanButton.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  product: PropTypes.object,
};

const SubscribeButton = ({ product, setIsLoading }) => {
  const [subscribing, setSubscribing] = useState(false);
  const handleSubscribe = () => {
    if (subscribing) {
      return;
    }
    setSubscribing(true);
    setIsLoading(true);
    apiRequest('/billing/stripe/subscribe/' + product.payload)
      .then((resp) => {
        return resp.json();
      })
      .then((data) => {
        window.location.href = data.subscribe_url;
        setIsLoading(false);
        setSubscribing(false);
      });
  };

  return (
    <Link
      sx={{
        width: '100%',
        padding: '15px 0',
        background: subscribing ? DARK_GREY : DEEP_PURPLE,
        color: WHITE,
        borderRadius: '50px',
        boxShadow: 'none',
        fontSize: '12px',
        fontWeight: 900,
        fontFamily: 'GeometriaExtraBold',
        textTransform: 'uppercase',
        opacity: product === null ? 0.5 : 1,
        cursor: 'pointer',
        '&: hover': {
          background: DEEP_PURPLE_HOVERED,
          color: WHITE,
        },
        '&:active': {
          background: DEEP_PURPLE_PRESSED,
          color: WHITE,
        },
      }}
      onClick={subscribing ? null : handleSubscribe}
      underline="none"
    >
      Choose my plan
    </Link>
  );
};

SubscribeButton.propTypes = {
  product: PropTypes.object,
  setIsLoading: PropTypes.func,
};

export const FreeTrial = ({ trialId }) => {
  const [selectedPlan, setPlan] = useState(null);
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    apiRequest(`/billing/stripe/products/${trialId}`).then((resp) => {
      resp.json().then((products) => {
        setProducts(products ?? []);
        setPlan(products?.items[0]?.title);
        setIsLoading(false);
      });
    });
  }, []);
  const handleClick = (product) => async (e) => {
    e.preventDefault();
    setPlan(product);
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <Box sx={{ mb: '20px', width: '100%', maxWidth: '600px' }}>
        <Typography
          sx={{ mb: '20px', fontWeight: 900 }}
          variant="h5"
          component="h1"
          gutterBottom
        >
           {`Choose your plan after ${products.trial}-Day free trial`}
        </Typography>
        <Typography sx={{ mb: '35px' }} variant="body2">
          <div style={{ color: DEEP_PURPLE }}>{`$0 for ${products.trial} days`}</div>
          <div>Cancel anytime.</div>
          No penalties or fees.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {products.items.map((product) => (
            <PlanButton
              key={product.payload}
              selected={selectedPlan === product.title}
              product={product}
              onClick={handleClick(product.title)}
            />
          ))}

          <Box
            sx={{
              width: '100%',
              maxWidth: '600px',
              mt: '50px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              '& button': { m: 1 },
            }}
          >
            <SubscribeButton
              product={products.items.find((item) => item.title === selectedPlan)}
              setIsLoading={setIsLoading}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ fontSize: '14px', color: GREY }}>
          Need Help? Contact Us
        </Typography>
        <Link
          sx={{ color: DEEP_PURPLE, fontSize: '14px' }}
          underline="none"
          href="mailto: support@funexpected.org"
        >
          support@funexpected.org
        </Link>
      </Box>
    </>
  );
};

FreeTrial.propTypes = {
  trialId: PropTypes.string,
};