import { loadState, saveState } from './Storage';

const endpoint =
  window.location.hostname === 'localhost'
    ? 'http://api.localho.st:8080'
    : `${window.location.protocol}//${window.location.host.replace(
        'www.',
        'api.'
      )}`;

let sessionState = loadState('session', null);

export const apiRequest = (path, params) => {
  const headers = sessionState
    ? { authorization: sessionState.token }
    : {};
  let url = endpoint + path;
  if (params) {
    url += '?' + new URLSearchParams(params);
  }
  return fetch(url, { headers });
};

export const setSession = (session) => {
  saveState('session', session)
  sessionState = session;
};
