import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage';
import { GetStarted } from '../pages/GetStarted';
import { RedeemCode } from '../pages/RedeemCode';
import { RedeemRoot } from '../pages/RedeemRoot';
import { SignUp } from '../pages/SignUp';
import { SignIn } from '../pages/SignIn';
import { Restore } from '../pages/Restore';
import { Root } from '../pages/Root';
import { Auth } from '../Auth';
import { Component } from 'react';
import { RedirectToPayment } from '../pages/RedirectToPayment';

class Redirect extends Component {
  componentDidMount() {
    window.location.assign('https://funexpectedapps.com/');
  }
  render() {
    return <span></span>;
  }
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Redirect />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/payment/:linkId',
    element: <RedirectToPayment />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/referral/:trialId?/:trialDays?',
    element: <Root />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/getstarted/:period',
    element: <GetStarted />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/redeem',
    element: <RedeemRoot />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <RedeemCode /> },
      {
        path: 'signup',
        element: <SignUp />,
      },
      {
        path: 'signin',
        element: <SignIn />,
      },
      {
        path: 'signin/:code',
        element: <SignIn />,
      },
      {
        path: 'restore',
        element: <Restore />,
      },
    ],
  },
  {
    path: '/auth/:payload/:next',
    element: <Auth />,
    errorElement: <ErrorPage />,
  },
]);

if (window.location.hash.startsWith('#/')) {
  router.navigate(window.location.hash.replace('#', ''));
}
